<template>
  <div class="disclosure">
    <h1>Flexdrive Charging Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">
      <strong>Effective as of 12/1/2019</strong>
    </p>
    <p>
      Electrify America and Lyft, Inc. (“Lyft”) have collaborated to provide this charging program to Lyft drivers, for
      the electric vehicles that Lyft drivers rent from Flexdrive Services, LLC (“Flexdrive”) to give rides to
      passengers using the rideshare platform operated by Lyft. By clicking “Agree” below, you agree that you are
      receiving the right to use up to 3 hours of included charging per session for your Flexdrive electric vehicle on
      Electrify America’s network of electric vehicle charging stations, subject to these Promotion Terms and
      Conditions. There is no additional fee, purchase, or subscription required for your access to this promotion.
    </p>
    <p>
      You sign up for this promotion by entering the access code originally provided to you by Lyft into the Electrify
      America app when prompted. After signing up, you can redeem this included charging time for the vehicle during
      your initiation of a charging session at any Electrify America charging station, exclusively by using the
      Electrify America app “Swipe to start” or NFC Pass feature with the appropriate plan selected. The first 3 hours
      of the charging session are included.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <ul>
      <li>
        There will be a 10-minute grace period immediately after charging has ended, during which you will not be
        assessed any parking or idle fees. Except for this grace period, included charging time
        <strong>does not include</strong> any parking or idle fees assessed at the charging station.
      </li>
      <li>
        <strong
          >This promotion is for your individual use only, with the Flexdrive rental vehicle that you use to provide
          rides to passengers through Lyft.</strong
        >
        You may only use this promotion with the account associated with your access code. You may not share the
        benefits of this promotion with anyone else (including any other Lyft drivers or Flexdrive vehicle renters
        and/or Electrify America customers).
      </li>
      <li>
        You must follow all product, vehicle, safety, and technical documentation included with your vehicle when
        charging at an Electrify America charging station.
      </li>
      <li>
        You must use the correct connector type and charge at the correct power level for the vehicle at the charging
        station.
      </li>
    </ul>
    <p>
      <strong
        >Please note also that your use of this promotion may be further limited by agreements or other legal terms
        between you and Lyft, or you and the owner/lessor of your electric vehicle. Electrify America does not have any
        control over those other third-party agreements, and is not responsible for any such additional limitations or
        restrictions, even if they conflict with these Promotion Terms and Conditions.</strong
      >
    </p>
    <p>
      This promotion will automatically end twenty-four (24) months from the date of your initial sign-up, after which
      you will not be able to access or use this promotion on your account. This included charging time is not
      returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise distributed to any third
      party for any reason.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://electrifyamerica.com/terms</router-link>, and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by selecting
      <strong>“Legal”</strong> in the Account Management portion of the mobile app (accessed by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map), then selecting
      <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link>
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services, and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this promotion at any time before its automatic termination date for
      any reason, in its sole discretion, with or without notice to you. Following the termination or expiration of this
      promotion for any reason, your access to the promotion will immediately cease, and you may not use or redeem any
      remaining portion of included charging time. At that time, your account will automatically convert to a basic
      Electrify America <strong>“Pass”</strong> membership account, at no additional fee or charge to you (neither
      one-time nor recurring). You may, but are not obligated to, sign up for a different type of subscription via the
      Electrify America website or mobile app.
    </p>
    <p>
      You understand that the charging program is provided by Lyft and Electrify America and that Flexdrive is not
      involved in set up or operation of the program.
    </p>
  </div>
</template>

<script>
export default {
  name: 'flexdrive-disclosures',
  metaInfo: {
    title: 'Flexdrive Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/flexdrive-disclosures/' }],
  },
};
</script>
